<template>
  <div v-if="moduleDetails" id="module-landing">
    <div class="module-banner">
      <k-banner :cover-image="coverImage">
        <div class="heading content-with-spacing">
          <div class="title-container">
            <h1>
              {{ moduleDetails.name }}
            </h1>
          </div>
          <div class="intro-text">
            <p><b class="welcome-text">Welcome to <em>{{moduleDetails.name}}</em>.</b></p>
            <div v-html="moduleDetails.description"></div>
          </div>
        </div>
      </k-banner>
      <div v-if="chapters && showProgress" class="module-progress-container content-with-spacing">
        <span v-if="chapterAssetCompletionScores.length" class="progress-label">Module Progress</span>
        <div class="completion-date" v-if="moduleDetails.expected_completion_date">
          <span>Due: <b>{{ parseTimestamp(moduleDetails.expected_completion_date)}}, ({{ Intl.DateTimeFormat().resolvedOptions().timeZone}})</b></span>
        </div>
        <k-progress v-if="chapterAssetCompletionScores.length" class="module-progress"
          shape="bar"
          :percentage="moduleCompletionPercentage"
          :valueMax="chapterAssetCompletionScores.length"
          :precision="1"
          progressTheme="darker"
          ariaLabel="Overall Module Completion"
          customText="Completed"
        ></k-progress>
      </div>
    </div>
    <div class="content-with-spacing">
      <div v-if="learningOutcomes && learningOutcomes.length" class="categories-container panel panel-default">
        <div class="category-header">
          <i class="fas fa-bullseye"></i>
          <h2 class="category-title">Learning Outcomes</h2>
        </div>
        <div class="list-grid category-content">
          <div class="list-grid-item col-md-6"
            v-for="outcome in learningOutcomes">
            <i class="fas fa-bullseye"></i>
            <div>
              <span>{{ outcome }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="categories-container overview">
        <div class="category-header">
          <i class="fad fa-list"></i>
          <h2 class="category-title">Module Overview</h2>
        </div>
        <template v-if="chapters && chapters.length">
          <chapter-panel
            v-for="chapter in chapters"
            :key="chapter.number"
            :moduleId="moduleId"
            :chapter="chapter"
          >
          </chapter-panel>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.module-banner {
  position: relative;
  margin-bottom: 30px;
}

.overview {
  padding-bottom: 15px;
}

.overview .category-header {
  padding: 24px 0;
}

.heading {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 60px 0 43px;
  align-items: center;
  position: relative;
  z-index: 1;
}

.heading .title-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.heading .title-container h1 {
  margin: 0 !important;
  padding: 0 !important;
}

.heading .title-logo,
.heading .title-container h1 {
  display: inline-block;
}

.heading .title-logo img {
  margin-right: 15px;
}

.heading .title-logo i {
  font-size: 2em;
  color: var(--kate-primary);
  padding-right: 15px;
}

.welcome-text {
  font-size: 1.2em;
}

.welcome-text em {
  font-style: unset;
  color: var(--kate-type-primary);
}

.intro-text {
  width: 100%;
  padding-top: 15px;
}
/* Categories */
.category-header {
  justify-content: space-between;
  vertical-align: middle;
  padding: 24px 15px;
}

.category-header,
.category-sub-header,
.category-content {
  display: flexbox;
  display: flex;
  flex-wrap: wrap;
}

.category-title {
  text-transform: capitalize;
  margin-top: 10px;
  flex: 1;
}

.category-sub-header {
  padding: 15px 15px 25px;
  width: 100%;
  font-size: 1.3em;
}

.category-header > i {
  color: var(--kate-type-accent);
  padding-right: 10px;
  font-size: 24px;
  align-self: center;
}

.category-content {
  padding: 15px 0;
  margin: 0 15px 15px;
}

.category-text {
  padding: 15px 0 0;
  width: 100%;
  font-size: 0.77em;
}

.question-icon sup {
  color: var(--kate-button-primary);
  cursor: pointer;
}

/* List grid */
.list-grid.category-content {
  padding: 0 0 15px;
}

.list-grid-item {
  display: flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: baseline;
}

.list-grid-item i {
  padding-right: 15px;
  color: var(--kate-type-accent);
}

.list-grid-item div h3 {
  margin-bottom: 5px;
}

span.progress-label {
  display: block;
  font-size: 1.4em;
  margin-bottom: 5px;
}
</style>

<style>
#module-landing .k-progress {
  width: 100%;
}

#module-landing .category-content > a:hover,
#module-landing .category-content > .lu-link:hover {
  text-decoration: none;
}

#module-landing .category-content > a .panel-default,
#module-landing .category-content > .lu-link .panel-default {
  transition: all 0.2s ease;
  transform: scale(1);
}

#module-landing .category-content > a:hover .panel-default,
#module-landing .category-content > .lu-link:hover .panel-default {
  transition: all 0.2s ease;
  transform: scale(0.98);
  box-shadow: var(--box-shadow);
}

</style>

<script>
import TimeMixin from '@mixins/time-mixins';
import SidebarMixin from '@mixins/sidebar-mixin';
import KProgress from '@base-components/k-progress.vue';
import ChapterPanel from './chapter-panel.vue';
import KBanner from '../programmes/k-banner.vue';

export default {
  components: {
    'k-banner': KBanner,
    'chapter-panel': ChapterPanel,
    KProgress,
  },

  mixins: [TimeMixin, SidebarMixin],

  props: {
    learningOutcomes: {
      type: Array,
    },
  },

  beforeMount() {
    this.$Loading.start();
    if (this.moduleDetails) {
      this.registerCrumbs();
    }
  },

  mounted() {
    if (this.sidebarReady) {
      this.$Loading.finish();
      this.$logger.pageReady();
    }
  },

  watch: {
    moduleDetails() {
      if (this.moduleDetails) {
        this.registerCrumbs();
      }
    },

    sidebarReady() {
      if (this.sidebarReady) {
        this.$Loading.finish();
        this.$logger.info('Loaded module landing page', { moduleId: this.moduleId }, true);
        this.$logger.pageReady();
      }
    },
  },

  computed: {
    moduleId() {
      return parseInt(this.$route.params.moduleId, 10);
    },

    apiEndpoint() {
      return `/api/curriculum/modules/${this.moduleId}`;
    },

    coverImage() {
      if (this.moduleDetails.cover_image) {
        return this.moduleDetails.cover_image;
      }
      return '';
    },
    showProgress() {
      const hasMandatoryAssets = this.assets.some(asset => asset.is_mandatory);
      return this.moduleCompletionPercentage !== null && hasMandatoryAssets;
    },
  },

  methods: {
    registerCrumbs() {
      this.$crumbs.register([
        {
          text: this.moduleDetails.programme_name,
          path: {
            name: 'programme_page',
            params: { programmeId: this.moduleDetails.programme_id },
          },
        },
        {
          text: this.moduleDetails.name,
          active: true,
        },
      ]);
    },
  },
};

</script>
