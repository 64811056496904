import useGlobalStore from '@stores/global';
import { sortObjectArray } from '@utils/sort-by-object-property';
import { getScoresForChapterAssets, average } from '@utils/module-completion-calculator';
import ChaptersMixin from './chapters-mixin';

export default {
  data() {
    return {
      store: useGlobalStore(),
    };
  },

  mixins: [ChaptersMixin],

  computed: {
    moduleDetails() {
      return this.$sidebar.moduleDetails;
    },

    chapterAssetCompletionScores() {
      // excludes optional assets, or if is_complete is undefined
      if (!this.$sidebar.chapters) {
        return [];
      }
      return getScoresForChapterAssets(this.$sidebar.chapters);
    },

    moduleCompletionPercentage() {
      return Math.floor(average(this.chapterAssetCompletionScores));
    },

    chapters() {
      if (this.$sidebar.chapters) {
        return sortObjectArray(this.$sidebar.chapters, 'number');
      }
      return undefined;
    },

    threads() {
      return this.$sidebar.threads;
    },

    sidebarReady() {
      return this.$sidebar.ready;
    },

    sidebarClass() {
      if (this.$sidebar.visibility && this.$sidebar.isEnabled(this.$route.name)) {
        return this.store.sidebarClass;
      }
      return '';
    },
  },
};
